import { H2, Icon, Logo, Spacer } from '@hapstack/ui'
import type { ComponentProps } from 'react'

import { PatternBackground } from './PatternBackground'

export const AuthLayout = ({ children }: ComponentProps<'div'>) => {
  return (
    <div className="grid w-full grid-cols-12">
      <section className="col-span-12 mx-auto h-full max-w-lg py-12 md:col-span-6">
        <Logo />
        <Spacer size="3xl" />
        <div className="w-96 max-w-lg">{children}</div>
      </section>

      <PatternBackground className="relative col-span-6 hidden md:block">
        <div className="absolute h-full w-full bg-black/50" />
        <div className="relative z-10 flex h-full flex-col justify-center gap-6 p-12">
          <div className=" flex flex-col gap-1 rounded-lg bg-secondary p-8">
            <Icon
              name="bar-chart-4"
              size="xl"
            />
            <H2>See your apps in one place.</H2>
            <p>
              It's time to ditch that spreadsheet. Minimize SaaS sprawl by
              managing everything in one place and maintaining one single source
              of truth.
            </p>
          </div>
          <div className="relative z-10 flex flex-col gap-1 rounded-lg bg-secondary p-8">
            <Icon
              name="mouse-pointer-click"
              size="xl"
            />
            <H2>View usage in real time.</H2>
            <p>
              Understand who's using every app (even ones you're not aware of),
              so you know if you're getting what you're paying for.
            </p>
          </div>
          <div className="relative z-10 flex flex-col gap-1 rounded-lg bg-secondary p-8">
            <Icon
              name="lightbulb"
              size="xl"
            />
            <H2>Make better decisions, faster.</H2>
            <p>
              Easily incorporate seat utilization, renewal schedules, and usage
              data into your software procurement decisions.
            </p>
          </div>
        </div>
      </PatternBackground>
    </div>
  )
}
