import { Outlet } from '@remix-run/react'

import { AppLayout } from '~/components/layouts/AppLayout'
import { AuthLayout } from '~/components/layouts/AuthLayout'

export default function AuthLayoutRoute() {
  return (
    <AppLayout>
      <AuthLayout>
        <Outlet />
      </AuthLayout>
    </AppLayout>
  )
}
